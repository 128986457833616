import { Badge } from "@mantine/core";

export const EventCard = ({ event }) => {
  return (
    <a
      style={{
        border: "0.5px lightgrey solid",
      }}
      href={"/event/" + event.event_id}
      className="block px-4 py-2 text-sm text-gray-700"
    >
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span style={{ maxWidth: "80%" }}>{event.event_title}</span>
        <Badge
          size="xs"
          color={
            event.event_type == "meeting"
              ? "green"
              : event.event_type == "event"
              ? "red"
              : null
          }
        >
          {event.event_type}
        </Badge>
      </span>
      <span
        style={{
          fontSize: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span>{new Date(event.event_start_time).toLocaleString()}</span>
        {event.event_end_time && " - "}
        <span>
          {event.event_end_time
            ? `${new Date(event.event_end_time).toLocaleString()}`
            : ""}
        </span>
      </span>
    </a>
  );
};
