import { Input } from "@mantine/core";
import { usePlacesWidget } from "react-google-autocomplete";
import { FormattedMessage, useIntl } from 'react-intl';

const LocationAutocomplete = ({ callBack }) => {
  const intl = useIntl();

  const { ref, autocompleteRef } = usePlacesWidget({
    onPlaceSelected: (place) => {
      callBack(place);
    },
    options: {
      types: ["address"],
    },
  });

  return (
    <>
      <div style={{ marginTop: 15, zIndex: 1600 }}>
      <FormattedMessage id="add_location" />
        <div>
          <Input
            options={{
              types: ["address"],
            }}
            placeholder={intl.formatMessage({id: "type_address"})}
            ref={ref}
          />
        </div>
      </div>
    </>
  );
};

export default LocationAutocomplete;
